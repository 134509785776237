/* Transcription.css */

/* テキストエリアの文字起こし結果と修正後のインタビューのフォントサイズを大きくする */
textarea.form-control {
  line-height: 1.6;
}

/* transcriptionData 表示エリア */
.transcription-display {
  white-space: pre-wrap;
  font-size: 1em; /* フォントサイズを調整 */
  padding: 10px; /* 少し余白を追加 */
  line-height: 1.6;
}
