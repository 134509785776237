/* App.css */

/* 全体のコンテナに余白だけを適用 */
.app-container {
  background-color: #f0f4f8; /* 全体の背景に軽いグレー */
  min-height: 100vh;
}

/* 使用状況の表示部分 */
.usage-info {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 400px; /* 必要に応じて調整 */
  position: fixed;
  top: 60px; /* デフォルトは右上 */
  right: 20px; /* 画面右端からの距離 */
  z-index: 1000; /* ナビゲーションバーの上に表示 */
}

/* レスポンシブデザイン: 使用状況の調整 */
@media (max-width: 768px) {
  .usage-info {
    position: fixed;
    bottom: 4px; /* 画面下部に移動 */
    right: 20px; /* 画面右端からの距離 */
    width: calc(100% - 40px); /* 幅を調整 */
  }
}
