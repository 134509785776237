/* TitleAndHeaderGenerator.css */

/* 出力結果内の h4 のサイズを調整 */
#outputContent h4 {
  font-size: 1.2rem; /* 小見出しのフォントサイズを調整 */
  margin-top: 1.5rem;
}

/* 出力結果内の p 要素のサイズを調整 */
#outputContent p {
  margin-bottom: 1rem;
}
